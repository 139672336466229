/* src/App.css */
:root {
  --navy: #0a192f;
  --light-navy: #112240;
  --lightest-navy: #233554;
  --slate: #8892b0;
  --light-slate: #a8b2d1;
  --lightest-slate: #ccd6f6;
  --white: #e6f1ff;
  --green: #64ffda;
  --green-tint: rgba(100, 255, 218, 0.1);
  --font-sans: 'Calibre', 'Inter', 'San Francisco', 'SF Pro Text', -apple-system, system-ui, sans-serif;
  --font-mono: 'SF Mono', 'Fira Code', 'Fira Mono', 'Roboto Mono', monospace;
  --fz-xxs: 12px;
  --fz-xs: 13px;
  --fz-sm: 14px;
  --fz-md: 16px;
  --fz-lg: 18px;
  --fz-xl: 20px;
  --fz-xxl: 22px;
  --fz-heading: 32px;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: var(--font-sans);
  background-color: var(--navy);
  color: var(--slate);
  font-size: var(--fz-xl);
  line-height: 1.3;
}

main {
  padding: 0 150px;
}

section {
  margin: 0 auto;
  max-width: 1000px;
  padding: 100px 0;
}

h1, h2, h3 {
  font-weight: 600;
  color: var(--lightest-slate);
  line-height: 1.1;
}

body {
  counter-reset: section; /* Initialize the counter */
}

.numbered-heading {
  display: flex;
  align-items: center;
  position: relative;
  margin: 10px 0 40px;
  width: 100%;
  font-size: clamp(26px, 5vw, var(--fz-heading));
  white-space: nowrap;
}

.numbered-heading::before {
  position: relative;
  left: 0; /* Align the number on the left */
  counter-increment: section; /* Increment the counter */
  content: '0' counter(section) '.'; /* Format the number */
  margin-right: 10px;
  color: var(--green);
  font-family: var(--font-mono);
  font-size: var(--fz-lg);
  font-weight: 400;
}

a {
  color: var(--green);
  text-decoration: none;
  cursor: pointer;
}

a:hover {
  color: var(--green-tint);
}

button, .button {
  color: var(--green);
  background-color: transparent;
  border: 1px solid var(--green);
  border-radius: 4px;
  padding: 1.25rem 1.75rem;
  font-size: var(--fz-xs);
  font-family: var(--font-mono);
  line-height: 1;
  text-decoration: none;
  cursor: pointer;
  transition: var(--transition);
}

button:hover, .button:hover {
  background-color: var(--green-tint);
  outline: none;
}

header {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100px;
  background-color: var(--navy);
  color: var(--lightest-slate);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 50px;
  z-index: 11;
  filter: none !important;
  pointer-events: auto !important;
  user-select: auto !important;
  backdrop-filter: blur(10px);
}

.logo-image {
  width: 150px; /* Adjust size as needed */
  height: auto;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.logo {
  font-size: 1.5rem;
  font-weight: bold;
}

.nav-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 500px;
}

.nav-link {
  color: var(--lightest-slate);
  padding: 0 10px;
}

.nav-link:hover {
  color: var(--green);
}

/* src/App.css or src/Hero.css */
.hero {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 100vh;
  padding: 0 20px; /* Add some padding for better spacing */
}

.hero-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.hero h1 {
  margin: 0 0 30px 4px;
  color: var(--green);
  font-size: clamp(var(--fz-sm), 5vw, var(--fz-md));
  font-family: var(--font-mono);
  font-weight: 400;
}

.hero h2 {
  margin: 0;
  font-size: clamp(40px, 8vw, 80px);
  color: var(--lightest-slate);
}

.hero h3 {
  margin: 10px 0;
  font-size: clamp(40px, 8vw, 80px);
  color: var(--light-slate);
}

.hero p {
  margin: 20px 0 0;
  max-width: 540px;
}

.hero a {
  width: fit-content !important;
}

.profile-image {
  width: 200px; /* Adjust size as needed */
  height: auto;
  border-radius: 50%; /* Optional: makes the image circular */
  margin-left: 20px;
  margin-top: -300px;
}

/* Container for animation */
.hero h1, .hero h2 {
  overflow: hidden; /* Ensures the text doesn't overflow */
  border-right: .15em solid black; /* Adds a cursor effect */
  white-space: nowrap; /* Prevents wrapping */
  letter-spacing: .15em; /* Adds spacing between letters */
  animation: typing 2s steps(30, end), blink .75s step-end infinite;
  font-family: var(--font-mono); /* Optional: Choose a monospaced font for typewriter effect */
}

.hero h1 {
  animation: typing 2s steps(30, end), blink .75s step-end infinite;
}

.hero h2 {
  animation: typing 2.5s steps(30, end), blink .75s step-end infinite;
  animation-delay: 0s; /* Optional: Delay the start for staggered effect */
}

/* Style for the sentences container */
.hero h3 {
  font-family: var(--font-mono); /* Monospaced font for typewriter effect */
  position: relative; /* Allows for absolute positioning of child elements */
  overflow: hidden; /* Hide text overflow */
  white-space: nowrap; /* Prevent text wrapping */
  width: 100%; /* Full width */
  height: 2em; /* Adjust height based on font size */
  font-size: 20px;
}

/* Individual sentence styling */
.hero h3 span {
  display: block; /* Ensure each span is on a new line */
  opacity: 0; /* Initially hidden */
  position: absolute; /* Position absolutely within the container */
  width: 100%;
  height: 100%;
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  animation: fadeOut 1s forwards; /* Default fade-out animation */
}

/* Show the current sentence */
.hero h3 span.active {
  opacity: 1;
  animation: typing 2s steps(30, end), fadeIn 1s forwards; /* Typing and fade-in animation */
}


/* Media Query for small screens (e.g., phones) */
@media (max-width: 600px) {
  .hero h3 {
    font-size: 10px; /* Smaller font size for small screens */
    width: 100%; /* Ensure full width */
    white-space: normal; /* Allow wrapping to prevent overflow */
  }
}

/* Media Query for medium screens (e.g., tablets) */
@media (min-width: 601px) and (max-width: 1024px) {
  .hero h3 {
    font-size: 14px; /* Adjust font size for medium screens */
    width: 100%; /* Ensure full width */
    white-space: normal; /* Allow wrapping to prevent overflow */
  }
}

/* src/Skills.css */
.skills {
  padding: 50px;
  background-color: var(--light-navy);
  text-align: center;
}

.skills h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.skills-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.skill-item {
  background: var(--light-navy);
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  width: 200px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.skill-item h3 {
  margin-top: 10px;
  font-size: 1.5rem;
}

.skill-item p {
  margin-top: 10px;
  color: #666;
}

/* src/Components/ProjectShowcase.css */

.project-showcase {
  position: relative; /* Ensure that the heading is positioned relative to this container */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: var(--light-navy);
}

.project-showcase h2 {
  position: absolute;
  top: 20px; /* Adjust as needed */
  margin: 0;
  font-size: 2rem; /* Adjust size as needed */
  color: var(--lightest-slate); /* Adjust color as needed */
  z-index: 2; /* Ensure the heading is above the carousel */
  padding: 10px;
  border-radius: 5px; /* Optional: rounding corners of the background */
}

.carousel {
  position: relative;
  width: 80%;
  max-width: 800px;
  overflow: hidden;
  border: 2px solid #ddd;
  border-radius: 10px;
}

.carousel-slides {
  display: flex;
  transition: transform 0.5s ease-in-out;
  width: 100%;
}

.carousel-slides img {
  width: 100%; /* Makes the image fit the width of the carousel */
  height: auto; /* Height adjusts based on width */
  max-height: 500px; /* Limits the maximum height */
  object-fit: contain; /* Ensures the entire image is visible without cropping */
  border-radius: 10px;
}

.carousel-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 1;
}

.carousel-button.prev {
  left: 10px;
}

.carousel-button.next {
  right: 10px;
}

.carousel-button:focus {
  outline: none;
}

/* src/App.css */
.contact-form-section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
}

.contact-form-box {
  background-color: var(--light-navy);
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 10px 30px -15px var(--navy-shadow);
  max-width: 500px;
  width: 100%;
}

.contact-form-box h2 {
  color: var(--lightest-slate);
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  color: var(--light-slate);
  margin-bottom: 5px;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid var(--light-slate);
  border-radius: 4px;
  background-color: var(--light-navy);
  color: var(--lightest-slate);
}

.form-group textarea {
  resize: vertical;
}

button[type="submit"] {
  background-color: var(--green);
  border: none;
  color: var(--white);
  padding: 15px;
  border-radius: 4px;
  cursor: pointer;
}

button[type="submit"]:hover {
  background-color: var(--green-tint);
}

/* src/Footer.css */
footer {
  background-color: var(--light-navy);
  color: var(--lightest-slate);
  padding: 50px 0;
}

footer h2 {
  margin-bottom: 20px;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1000px;
  margin: 0 auto;
}

.social-media-links,
.nav-links {
  display: flex;
  gap: 20px;
}

.social-media-links a,
.nav-links a {
  color: var(--lightest-slate);
  transition: color 0.3s;
}

.social-media-links a:hover,
.nav-links a:hover {
  color: var(--green);
}

/* src/Components/Navbar.css */
.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: var(--navy);
  color: var(--lightest-slate);
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  padding: 0 20px;
}

.nav-links {
  display: flex;
  gap: 20px;
}

.nav-links a {
  color: var(--lightest-slate);
  text-decoration: none;
  padding: 10px;
  transition: color 0.3s;
}

.nav-links a:hover {
  color: var(--green);
}

/* Responsive Design */
@media (max-width: 768px) {
  main {
    padding: 0 20px;
  }

  header {
    padding: 0 20px;
    height: auto;
    flex-direction: column;
    align-items: flex-start;
  }

  .logo-image {
    width: 120px;
  }

  .nav-links {
    width: auto;
    flex-direction: column;
    gap: 10px;
  }

  .hero {
    flex-direction: column;
    padding: 0 10px;
  }

  .hero-content {
    text-align: center;
  }

  .hero h1, .hero h2, {
    font-size: clamp(20px, 5vw, 40px);
  }

  .profile-image {
    width: 150px;
  }

  .skills-container {
    flex-direction: column;
  }

  .skill-item {
    width: 100%;
  }

  .carousel {
    width: 100%;
    max-width: none;
  }

  .contact-form-box {
    width: 90%;
  }

  footer {
    flex-direction: column;
    text-align: center;
  }

  footer .nav-links {
    align-items: center;
    margin-top: 20px;
  }
}

@media (max-width: 480px) {
  body {
    font-size: var(--fz-md);
  }

  .hero h1, .hero h2,  {
    font-size: clamp(18px, 5vw, 32px);
  }

  .profile-image {
    width: 120px;
  }

  .contact-form-box {
    padding: 20px;
  }

  .footer-container {
    flex-direction: column;
    text-align: center;
  }

  footer .social-media-links,
  footer .nav-links {
    margin-bottom: 20px;
  }

  footer .social-media-links ul li {
    margin: 5px 0;
  }
}

/* src/App.css */
.education {
  padding: 2rem;
  background-color: var(--light-navy);
}

.education {
  margin-bottom: 2rem; /* Adjust the margin as needed */
}

.education h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.education-item {
  margin-bottom: 1.5rem;
}

.education-item h3 {
  font-size: 1.5rem;
  margin: 0;
}

.education-item p {
  margin: 0.5rem 0;
}

.transcript-link {
  color: #007acc;
  text-decoration: none;
  font-weight: bold;
  margin-top: 8px;
  display: inline-block;
}

.transcript-link:hover {
  text-decoration: underline;
}

#work {
  padding: 2rem;
}

.projects {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

.project {
  background: var(--light-navy);
  padding: 1rem;
  border-radius: 8px;
  display: flex;
  align-items: flex-start;
  gap: 1rem;
}

.project-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 8px;
}

.project-details h3 {
  margin: 0 0 0.5rem 0;
  font-size: 1.2rem;
}

.project-details p {
  font-size: 0.9rem;
  margin: 0 0 1rem 0;
}

.project-details ul {
  list-style-type: disc;
  padding-left: 1.2rem;
}

.project-details ul li {
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
}

/* Media queries for responsive design */

/* Small screens (mobile devices) */
@media (max-width: 600px) {
  #work {
    padding: 1rem;
  }

  .projects {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }

  .project {
    padding: 0.8rem;
  }

  .project-details h3 {
    font-size: 1.1rem;
  }

  .project-details ul li {
    font-size: 0.85rem;
  }
}

/* Medium screens (tablets, small laptops) */
@media (min-width: 601px) and (max-width: 1024px) {
  .projects {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }

  .project {
    padding: 1rem;
  }

  .project-image {
    max-width: 200px;
  }

  .project-details h3 {
    font-size: 1.2rem;
  }

  .project-details ul li {
    font-size: 0.9rem;
  }
}

/* Large screens (desktops) */
@media (min-width: 1025px) {
  .projects {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }

  .project {
    flex-direction: row;
    align-items: flex-start;
    text-align: left;
  }

  .project-image {
    width: 150px;
    height: auto;
  }

  .project-details {
    flex: 1;
  }

  .project-details h3 {
    font-size: 1.4rem;
  }

  .project-details ul li {
    font-size: 1rem;
  }
}





/* Keyframes for typing animation */
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes blink {
  0% {
    border-color: transparent;
  }
  100% {
    border-color: transparent;
  }
}

/* Keyframes for typing animation */
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

/* Keyframes for fade-in effect */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Keyframes for fade-out effect */
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}